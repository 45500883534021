import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import Sound from "react-sound";
import song from '../../assets/songs/Demo.mov'
import { image } from "../../assets/image";
export default function EventHome() {
    const eventDate = new Date("2025-03-14T19:00:00");
    const [timeLeft, setTimeLeft] = useState(getTimeLeft());
    const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
    const [current, setCurrent] = useState(0);
    function getTimeLeft() {
        const now = new Date();
        const difference = eventDate - now;
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(getTimeLeft());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const toggleMusic = () => {
        setPlayStatus(
            Sound.status.PLAYING
        );
        setCurrent(1);
    };

    return (
        <div className="min-h-screen bg-black text-white font-body">
            {/* Background Music */}
            <Sound
                url={song}
                playStatus={playStatus}
                loop={true}
                volume={50} // Adjust volume (0-100)
            />

            {/* Hero Section */}
            {
                current === 0 && (
                    <div
                        className="relative h-screen bg-cover bg-center flex flex-col font-Goldage justify-center items-center text-center"
                        style={{ backgroundImage: "url('/rd-burman-bg.jpg')" }}
                    >
                        <motion.button
                            whileHover={{ scale: 1.1 }}
                            className="bg-logo px-6 py-2 mt-6 text-3xl rounded-full transition-all duration-700"
                            onClick={toggleMusic}
                        >
                            Tap to Reveal Now
                        </motion.button>
                    </div>
                )
            }
            {
                current === 1 && (
                    <div
                        className="relative h-screen bg-cover bg-center flex flex-col font-Goldage py-5  backdrop-blur-xl items-center text-center bg-white"
                        style={{ backgroundImage: `url(${image.web1})` }}
                    >
                        <motion.h1 initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} className="text-5xl md:text-7xl font-bold text-event text-end w-full px-2">
                            Mehfil Pancham Da Ki
                        </motion.h1>
                        <motion.h1 initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} className="text-5xl md:text-6xl text-end w-full text-event py-2 px-2">
                            Tribute to legendary R.D. Burman
                        </motion.h1>
                        <motion.p initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.2 }} className="mt-4 text-lg md:text-3xl w-full text-end text-black">
                            (A Solo Instrumental Show) with His Ladla
                            <span className=" text-event px-2 underline-offset-8 underline">Vijay Katkar</span>
                        </motion.p>
                        <motion.p initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.4 }} className="mt-2 text-xl md:text-3xl font-semibold w-full text-end px-2 text-black">
                            Feel the magic of music, live the rhythm of legends!
                        </motion.p>

                        {/* Play/Pause Button */}


                        {/* Event Countdown */}
                        <div className="text-center py-5 self-start px-4">
                            <h2 className="text-3xl font-bold text-event">Event Countdown</h2>
                            <div className="flex justify-center text-event space-x-6 mt-4 text-xl md:text-6xl">
                                <span>{timeLeft.days}d</span>
                                <span>{timeLeft.hours}h</span>
                                <span>{timeLeft.minutes}m</span>
                                <span>{timeLeft.seconds}s</span>
                            </div>
                        </div>

                        {/* Book Now Button */}
                        <motion.button whileHover={{ scale: 1.1 }} className="bg-logo  px-6 py-2 text-4xl rounded-full  fixed bottom-10 transition-all duration-700">
                            Book Now
                        </motion.button>
                    </div>
                )
            }
        </div>
    );
}
