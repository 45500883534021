export const image = {
    logo: require('./images/squiggle.png'),
    sample1: require('./images/sample1.png'),
    sample2: require('./images/sample2.png'),
    sample3: require('./images/sample3.png'),
    sample4: require('./images/sample4.png'),
    culture: require('./images/culture.png'),
    last: require('./images/last.png'),
    reviews: require('./images/reviews.png'),
    guest: require('./images/guest.png'),
    staff: require('./images/staff.png'),
    tips: require('./images/tips.png'),
    turnover: require('./images/turnover.png'),
    cost: require('./images/cost.png'),
    op: require('./images/frame.png'),
    partner: require('./images/partner.png'),
    google: require('./images/google.png'),
    upi: require('./images/upi.png'),
    cash: require('./images/cash.png'),
    card: require('./images/card.png'),
    tick: require('./images/tick.png'),
    plus: require('./images/plus.png'),
    become: require('./images/become.jpeg'),




    // Brands
    chai: require('./images/Brand/chai.png'),

    // Dashboard
    activity: require('./images/dasboard/activity.png'),
    board: require('./images/dasboard/board.png'),
    restaurant: require('./images/dasboard/restaurant.png'),
    cup: require('./images/dasboard/cup.png'),
    plate: require('./images/dasboard/plate.png'),
    users: require('./images/dasboard/users.png'),
    setting: require('./images/dasboard/setting.png'),
    dashboard: require('./images/dasboard/dashboard.png'),
    trash: require('./images/dasboard/trash.png'),
    edit: require('./images/dasboard/edit.png'),
    switch: require('./images/dasboard/switch.png'),


    // image
    web1: require('./images/events/website.jpg')

}