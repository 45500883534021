import React, { useEffect } from 'react'
import './helper/chartConfig'
import Home from './pages/home/Home'
import { Routes, Route } from 'react-router-dom'
import Login from './pages/merchant/auth/Login'
import Forgot from './pages/merchant/auth/Forgot'
import Sent from './pages/merchant/auth/Sent'
import Notfound from './pages/constant/Notfound'
import WhoWeAre from './pages/home/Who'
import OrderPay from './pages/home/OrderPay'
import PayTable from './pages/home/PayTable'
import Demo from './pages/home/Demo'
import CustomerHome from './pages/customer/CustomerHome'
import Menu from './pages/customer/Menu'
import Cart from './pages/customer/Cart'
import ItemInfo from './pages/customer/ItemInfo'
import LiveOrders from './pages/merchant/dashboard/LiveOrders'
import Dashboard from './pages/merchant/dashboard/Dashboard'
import MenuManagment from './pages/merchant/dashboard/Menu'
import Table from './pages/merchant/dashboard/Table'
import Restaurant from './pages/merchant/dashboard/Restaurant'
import Employee from './pages/merchant/dashboard/Employee'
import Settings from './pages/merchant/dashboard/Settings'
import PrivacyPolicy from './pages/constant/Privacy'
import TermsOfService from './pages/constant/Terms'
import ContactUs from './pages/constant/ContactUs'
import CancellationRefund from './pages/constant/CancellationRefund'
import ShippingDelivery from './pages/constant/ShippingDelivery'
import Kitchen from './pages/merchant/dashboard/Kitchen'
import useVisibilityChange from './helper/useVisibilityChange'
import { setupNotifications } from './helper/firebase.utils'
import { sendNativeNotification, toastNotification } from './helper/notificationHelpers'
import Partners from './pages/constant/Partners'
import Blog from './pages/constant/Blog'
import CustomerLogin from './pages/customer/auth/Login'
import CustomerRegsiter from './pages/customer/auth/Register'
import Checkout from './pages/customer/Checkout'
import Success from './pages/customer/Success'
import Bill from './pages/customer/Bill'
import EmptyCart from './pages/customer/EmptyCart'
import Search from './pages/customer/Search'
import BecomePartner from './pages/home/BecomePartner'
import Done from './pages/customer/Done'
import EventHome from './pages/events/Home'

export default function App() {
  const isForeground = useVisibilityChange();
  useEffect(() => {
    setupNotifications((message) => {
      if (isForeground) {
        // App is in the foreground, show toast notification
        toastNotification({
          title: message?.title,
          description: message?.body,
          status: "info",
        });
      } else {
        // App is in the background, show native notification
        sendNativeNotification({
          title: message?.title,
          body: message?.title,
        });
      }
    });
  }, []);
  return (
    <Routes>
      {/* Normal Routes */}
      <Route path='/' element={<Home />} />
      <Route path='/who-we-are' element={<WhoWeAre />} />
      <Route path='/order-and-pay' element={<OrderPay />} />
      <Route path='/pay-at-table' element={<PayTable />} />
      <Route path='/get-started' element={<Demo />} />
      <Route path='/become-a-partner' element={<BecomePartner />} />
      <Route path='/partners' element={<Partners />} />
      <Route path='/blogs' element={<Blog />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='/terms-and-condition' element={<TermsOfService />} />
      <Route path='/contact-us' element={<ContactUs />} />
      <Route path='/cancellation-refund' element={<CancellationRefund />} />
      <Route path='/shipping-delivery' element={<ShippingDelivery />} />

      {/* Customer Routes */}
      <Route path='/restaurant' element={<CustomerHome />} />
      <Route path='/pay-bill' element={<Bill />} />
      <Route path='/search' element={<Search />} />
      <Route path='/empty-cart' element={<EmptyCart />} />
      <Route path='/menu' element={<Menu />} />
      <Route path='/cart' element={<Cart />} />
      <Route path='/product-info' element={<ItemInfo />} />
      <Route path='/checkout' element={<Checkout />} />
      <Route path='/success' element={<Success />} />
      <Route path='/login' element={<CustomerLogin />} />
      <Route path='/register' element={<CustomerRegsiter />} />
      <Route path='/payment-done' element={<Done />} />

      {/* Merchant Routes */}
      <Route path='/merchant/login' element={<Login />} />
      <Route path='/merchant/reset-password' element={<Forgot />} />
      <Route path='/merchant/reset-link' element={<Sent />} />
      <Route path='/merchant/dashboard' element={<Dashboard />} />
      <Route path='/merchant/live-orders' element={<LiveOrders />} />
      <Route path='/merchant/menu-management' element={<MenuManagment />} />
      <Route path='/merchant/table-management' element={<Table />} />
      <Route path='/merchant/restaurant-details' element={<Restaurant />} />
      <Route path='/merchant/employees' element={<Employee />} />
      <Route path='/merchant/settings' element={<Settings />} />
      <Route path='/merchant/kitchen' element={<Kitchen />} />

      {/* events */}
      <Route path='/events/home' element={<EventHome />} />


      {/* 404 */}
      <Route path='/*' element={<Notfound />} />

    </Routes>
  )
}
